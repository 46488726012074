export const formatCurrency = (amount: number, currencySymbol = "$", fixed = 2) => {
  if (isNaN(amount)) {
    return `${currencySymbol}0`;
  }
  // Formatear el número con separadores de miles y dos decimales
  // const formattedAmount = amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  // const formattedAmount = amount.toString().replace(/\d(?=(\d{3})+\.)/g, "$&,");
  // const formattedAmount = amount.toFixed(2);
  const formattedAmount = amount
    .toFixed(fixed)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Retornar el string formateado con el símbolo de la moneda
  return `${currencySymbol}${formattedAmount}`;
};

export const formatHours = (hours: number) => {
  const days = Math.floor(hours / 24);
  const hourRemaining = hours - days * 24;

  if (days !== 0) {
    if (days == 1) {
      return `${days} día ${hourRemaining} horas`;
    } else {
      return `${days} días ${hourRemaining} horas`;
    }
  }

  return `${hourRemaining} horas`;
};
