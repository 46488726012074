import { Dialog } from "@mui/material";

import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "@store/store";
import { DialogType, RejectDialogProps, ValidateDialogProps } from "@store/slices/appSlice";

import { ValidateDialogSegment } from "./ValidateDialogSegment";
import { RejectDocumentDialogSegment } from "./RejectDocumentDialogSegment";

export const DialogManager = () => {
  const dialogConfig = useSelector((state: RootState) => state.app.components.dialog);

  const dialogs = new Map<DialogType, React.ReactNode>([
    [DialogType.NONE, <></>],
    [
      DialogType.REJECT_DOC,
      <RejectDocumentDialogSegment
        document={(dialogConfig.props as RejectDialogProps)?.document}
        isProcessing={dialogConfig?.isProcessing ?? false}
        onBackClick={dialogConfig.onRejectClick ?? (() => {})}
        onConfirmClick={dialogConfig.onAcceptClick ?? (() => {})}
      />,
    ],
    [
      DialogType.VALIDATE,
      <ValidateDialogSegment
        type={"DOCVALIDATE"}
        title={(dialogConfig.props as ValidateDialogProps)?.title}
        isProcessing={dialogConfig?.isProcessing}
        onBackClick={dialogConfig.onRejectClick ?? (() => {})}
        onContinueClick={dialogConfig.onAcceptClick ?? (() => {})}
      />,
    ],
  ]);

  return <Dialog open={dialogConfig.shown}>{dialogs.get(dialogConfig.type)}</Dialog>;
};
