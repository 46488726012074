import { MouseEvent, ReactNode, Suspense, useEffect, useState } from "react";
import { AppBar, Box, Button, IconButton, Menu, MenuItem, Toolbar } from "@mui/material";

import { useDispatch } from "react-redux";
import { fetchAuthSession, signOut } from "aws-amplify/auth";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { store } from "@store/store";
import { CustomModal } from "@components/CustomModal";
// import { setShowValidateFileModal } from "@store/slices/appSlice";
import { RejectApplicationDialogSegment } from "@components/RejectApplicationDialogSegment";

import logo from "@assets/lendia_full.svg";
import AccountIcon from "@assets/account_icon.svg";
// import { RejectDocumentDialogSegment } from "@components/RejectDocumentDialogSegment";
import { createSelector } from "@reduxjs/toolkit";
import { DialogManager } from "@components/DialogManager";

export const MainLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [childrenModal, setChildrenModal] = useState<ReactNode | null>();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  // const modalState = useSelector((state: RootState) => state.app.components.modal);

  const permissions = (state = store.getState()) => state.app.session.permissions;
  const sessionPermissions = createSelector([permissions], (permissions) => {
    return permissions?.filter((permission) => ["MREZ", "MLNE"].includes(permission.module.code));
  });

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    dispatch({ type: "CLEAN_STORE" });
    await signOut();
    navigate("/login");
  };

  const handleOnRejectApplicationClicked = () => {
    setChildrenModal(
      <RejectApplicationDialogSegment
        onBackClick={handleOnModalBackClicked}
        onConfirmClick={handleOnModalConfirmClicked}
      />,
    );
    setShowConfirmModal(true);
  };

  const handleOnModalBackClicked = () => {
    setShowConfirmModal(false);
  };

  const handleOnModalConfirmClicked = () => {
    setShowConfirmModal(false);
  };

  useEffect(() => {
    if (["/login", "/change_password", "/recovery"].some((item) => item === location.pathname) === false) {
      fetchAuthSession().then((value) => {
        if (value.tokens === undefined) {
          navigate("/login");
        }
      });
    }
  }, []);

  return (
    <>
      <Box height={"100vh"}>
        <AppBar position="static" sx={{ bgcolor: "white", boxShadow: 0 }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              <Box component="img" src={logo} alt="logo" sx={{ height: 25 }} />
            </Box>
            {location.pathname === "/expediente" && (
              <Box display={"flex"} flexDirection={"row"} columnGap={2}>
                {sessionPermissions(store.getState()).find(
                  (permission) => permission.module.code === "MREZ" && permission.can_watch,
                ) && (
                  <Button variant="indian_red" onClick={handleOnRejectApplicationClicked}>
                    Rechazar
                  </Button>
                )}
                {sessionPermissions(store.getState()).find(
                  (permission) => permission.module.code === "MLNE" && permission.can_watch,
                ) && (
                  <Button variant="cardinal" sx={{ maxWidth: "200px", lineHeight: "20px" }}>
                    Rechazar y envíar a Listas Negras
                  </Button>
                )}
              </Box>
            )}
            {location.pathname === "/" && (
              <Box ml={4}>
                <IconButton onClick={handleMenu}>
                  <img src={AccountIcon} width={"22px"} height={"22px"} />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  onClose={handleClose}
                  open={Boolean(anchorEl)}
                >
                  <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
                </Menu>
              </Box>
            )}
          </Toolbar>
        </AppBar>
        <Box mx={2} height={"93vh"}>
          <Suspense fallback={<></>}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>

      <CustomModal open={showConfirmModal} children={childrenModal} />
      {/* <CustomModal
        open={modalState.shown}
        children={
          modalState.modal === "RejectDocument" && (
            <RejectDocumentDialogSegment
              document={undefined}
              isProcessing={false}
              onBackClick={modalState.onActionOne}
              onConfirmClick={modalState.onActionTwo}
            />
          )
        }
      /> */}
      <DialogManager />
    </>
  );
};
