import { DocumentInfo } from "@interfaces/documents";
import { BpmUser, Permission } from "@interfaces/users";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UUID } from "crypto";

export interface ApplicationSelected {
  id: UUID | undefined;
  solicitudId: number;
  razonSocial: string;
  rfc: string;
  nombre: string;
  montoSolicitado: string;
  plazoSolicitado: number;
  montoContratado: string;
  plazoContratado: number;
  tiempoPantalla: number;
  tiempoTotal: number;
  tiempoTotalProceso: string;
  etapa: string;
  subEtapa: string;
  pantalla: string;
  macroCanal: string;
  canal: string;
  asignacion: string;
  perdioInteres: number;
  dictamenCompletado: boolean;
  canalId: string;
  correoEjecutivo: string;
  fullData: string;
  company_docs_validated: boolean;
  shareholder_docs_validated: boolean;
  owners_docs_validated: boolean;
  tax_systems: {
    code: string;
  };
  status_code: string;
  existsSDC: boolean;
}

export enum DialogType {
  NONE,
  REJECT_DOC,
  VALIDATE,
}

export interface RejectDialogProps {
  document: DocumentInfo;
}

export interface ValidateDialogProps {
  title: string;
}

export interface DialogConfig {
  shown: boolean;
  type: DialogType;
  props: undefined | RejectDialogProps | ValidateDialogProps;
  isProcessing?: boolean;
  onRejectClick?: () => void;
  onAcceptClick?: () => void;
  onCloseClick?: () => void;
}

export interface AppSlice {
  applicationSelected: ApplicationSelected;
  session: {
    user: BpmUser;
    permissions: Array<Permission>;
    recovery: {
      username: string;
    };
  };
  validateFileModal: {
    show: boolean;
  };
  components: {
    dialog: DialogConfig;
  };
}

const initialState: AppSlice = {
  applicationSelected: {
    id: undefined,
    solicitudId: 0,
    razonSocial: "",
    rfc: "",
    nombre: "",
    montoSolicitado: "",
    plazoSolicitado: 0,
    tiempoPantalla: 0,
    tiempoTotal: 0,
    etapa: "",
    subEtapa: "",
    pantalla: "",
    macroCanal: "",
    canal: "",
    asignacion: "",
    perdioInteres: 0,
    montoContratado: "",
    plazoContratado: 0,
    tiempoTotalProceso: "",
    dictamenCompletado: false,
    fullData: "",
    company_docs_validated: false,
    shareholder_docs_validated: false,
    owners_docs_validated: false,
    canalId: "",
    correoEjecutivo: "",
    tax_systems: {
      code: "",
    },
    status_code: "",
    existsSDC: false,
  },
  session: {
    user: {
      id: "0-0-0-0-0",
      name: "",
      last_name: "",
      last_name_2: "",
      rol_id: "0-0-0-0-0",
      created_at: "",
      updated_at: "",
    },
    permissions: [],
    recovery: {
      username: "",
    },
  },
  validateFileModal: {
    show: false,
  },
  components: {
    dialog: {
      shown: false,
      type: DialogType.NONE,
      props: undefined,
      onRejectClick: () => {},
      onAcceptClick: () => {},
      onCloseClick: () => {},
    },
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setApplicationSelected: (state, action: PayloadAction<ApplicationSelected>) => {
      state.applicationSelected = action.payload;
    },
    setApplicationCompleted: (state, action: PayloadAction<boolean>) => {
      state.applicationSelected.dictamenCompletado = action.payload;
    },
    setApplicationStateCode: (state, action: PayloadAction<string>) => {
      state.applicationSelected.status_code = action.payload;
    },
    setBpmUserLogged: (state, action: PayloadAction<BpmUser>) => {
      state.session.user = action.payload;
    },
    setUserPermissions: (state, action: PayloadAction<Array<Permission>>) => {
      state.session.permissions = action.payload;
    },
    setCompanyDocsValidated: (state, action: PayloadAction<boolean>) => {
      state.applicationSelected.company_docs_validated = action.payload;
    },
    setShareHolderDocsValidated: (state, action: PayloadAction<boolean>) => {
      state.applicationSelected.shareholder_docs_validated = action.payload;
    },
    setOwnersDocsValidated: (state, action: PayloadAction<boolean>) => {
      state.applicationSelected.owners_docs_validated = action.payload;
    },
    setShowValidateFileModal: (state, action: PayloadAction<boolean>) => {
      state.validateFileModal.show = action.payload;
    },
    setRecoveryUsername: (state, action: PayloadAction<string>) => {
      state.session.recovery.username = action.payload;
    },
    setDialogConfig: (state, action: PayloadAction<DialogConfig>) => {
      state.components.dialog = action.payload;
    },
    setDialogConfigProcessing: (state, action: PayloadAction<boolean>) => {
      state.components.dialog.isProcessing = action.payload;
    },
    setExistSDC: (state, action: PayloadAction<boolean>) => {
      state.applicationSelected.existsSDC = action.payload;
    },
  },
});

export const {
  setApplicationSelected,
  setApplicationCompleted,
  setApplicationStateCode,
  setBpmUserLogged,
  setUserPermissions,
  setCompanyDocsValidated,
  setShareHolderDocsValidated,
  setOwnersDocsValidated,
  setShowValidateFileModal,
  setRecoveryUsername,
  setDialogConfig,
  setDialogConfigProcessing,
  setExistSDC,
} = appSlice.actions;
export default appSlice.reducer;
