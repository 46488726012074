import { useEffect, useState } from "react";
import { Box, Button, Grid2, Typography } from "@mui/material";

import "dayjs/locale/es";
import { useNavigate } from "react-router-dom";
import { createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

import { RootState, store } from "@store/store";
import { setApplicationStateCode, setShowValidateFileModal } from "@store/slices/appSlice";

import { CustomModal } from "@components/CustomModal";
import { CompanyFileTab } from "@components/CompanyFileTab";
import { useLazyGetStatusQuery } from "@services/api/catalogs";
import { CustomTabPanel, CustomTabs } from "@components/CustomTabs";
import { GuaranteeOwnerFileTab } from "@components/GuaranteeOwnerFileTab";
import { ValidateDialogSegment } from "@components/ValidateDialogSegment";

import { useLazyGetDocumentsQuery, usePatchDocumentMutation } from "@services/api/documents";
import { usePostStatusApplicationMutation, usePutApplicationsMutation } from "@services/api/applications";

import BackIcon from "@assets/back_icon.svg";

export const Expediente = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [childrenModal, setChildrenModal] = useState<string>();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [configTabs, setConfigTabs] = useState<Array<string>>([]);

  const validateFileModal = useSelector((state: RootState) => state.app.validateFileModal);
  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  const permissions = (state = store.getState()) => state.app.session.permissions;
  const sessionPermissions = createSelector([permissions], (permissions) => {
    return permissions?.filter((permission) => ["MEEM", "MEAP"].includes(permission.module.code));
  });

  const [triggerGetStatus] = useLazyGetStatusQuery();
  const [triggerGetDocuments] = useLazyGetDocumentsQuery();
  const [trigetPatchDocument] = usePatchDocumentMutation();
  const [triggerPutApplication] = usePutApplicationsMutation();
  const [triggerPostStatusApplication] = usePostStatusApplicationMutation();

  const handleOnClickBack = (event) => {
    event.preventDefault();
    navigate("/");
  };

  const handleOnChangeTab = (_: string, index: number) => {
    setCurrentTab(index);
  };

  const handleOnModalBackClicked = () => {
    dispatch(setShowValidateFileModal(false));
  };

  const handleModalValidateClicked = async () => {
    setIsProcessing(true);
    await triggerPostStatusApplication({
      applicationId: applicationSelected.id ?? "0-0-0-0-0",
      statusCode: "CSEDV",
      disableSequenceCheck: true,
    }).unwrap();

    const docs = await triggerGetDocuments({
      loan_application_id: applicationSelected.id,
    });

    const docsNotValidated = docs.data
      ?.filter((doc) => doc.status && doc.validated === false)
      .map((doc) => {
        return trigetPatchDocument({
          applicationId: applicationSelected.id,
          documentId: doc.id ?? "0-0-0-0-0",
          body: {
            validated: true,
          },
        });
      });

    if (docsNotValidated !== undefined) {
      Promise.all(docsNotValidated);
    }
    setIsProcessing(false);
    dispatch(setShowValidateFileModal(false));
    dispatch(setApplicationStateCode("CSEDV"));
  };
  useEffect(() => {
    let tabs = Array();
    sessionPermissions(store.getState()).forEach((permission) => {
      if (permission.can_watch == false) {
        return;
      }
      switch (permission.module.code) {
        case "MEEM":
          tabs.push("Expediente empresa");
          break;
        case "MEAP":
          if (applicationSelected.tax_systems.code !== "CS002") {
            tabs.push("Expediente aval y propietarios");
            break;
          }
      }
    });
    setConfigTabs(tabs);
  }, []);

  useEffect(() => {
    if (validateFileModal.show) {
      setChildrenModal("VALIDATE");
    }
  }, [validateFileModal.show]);

  useEffect(() => {
    if (
      applicationSelected.company_docs_validated &&
      applicationSelected.shareholder_docs_validated &&
      applicationSelected.owners_docs_validated
    ) {
      setChildrenModal("VALIDATE");
    }
  }, [
    applicationSelected.company_docs_validated,
    applicationSelected.shareholder_docs_validated,
    applicationSelected.owners_docs_validated,
  ]);

  return (
    <>
      <Box marginTop={1}>
        <Grid2 container>
          <Grid2>
            <Button
              variant="text"
              startIcon={<img src={BackIcon} />}
              sx={{
                color: "#F2704F",
                px: 1,
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                },
              }}
              onClick={handleOnClickBack}
            >
              Regresar a solicitudes
            </Button>
          </Grid2>
        </Grid2>

        <Typography variant="h4" my={1}>
          {applicationSelected.razonSocial}
        </Typography>
        <Typography variant="h4" mt={1} mb={4}>
          ID SOLICITUD:{" "}
          <Typography component={"span"} variant="h4" fontWeight={600}>
            {applicationSelected.solicitudId}
          </Typography>
        </Typography>
        <Box px={10}>
          <CustomTabs titleTabs={configTabs} onChangeTab={handleOnChangeTab} />
          {sessionPermissions(store.getState()).find((permission) => permission.module.code === "MEEM") && (
            <CustomTabPanel index={0} value={currentTab} alias="normal">
              <Box flexGrow={1} display={currentTab == 0 ? "flex" : "none"}>
                <CompanyFileTab />
              </Box>
            </CustomTabPanel>
          )}
          {sessionPermissions(store.getState()).find((permission) => permission.module.code === "MEAP") && (
            <CustomTabPanel index={1} value={currentTab} alias="normal">
              <Box flexGrow={1} display={currentTab == 1 ? "flex" : "none"}>
                <GuaranteeOwnerFileTab />
              </Box>
            </CustomTabPanel>
          )}
        </Box>
      </Box>
      <CustomModal open={validateFileModal?.show}>
        {childrenModal === "VALIDATE" && (
          <ValidateDialogSegment
            type={"DOCVALIDATE"}
            isProcessing={isProcessing}
            title={`Estas seguro de validar el expediente de:`}
            onBackClick={handleOnModalBackClicked}
            onContinueClick={handleModalValidateClicked}
          />
        )}
      </CustomModal>
    </>
  );
};
