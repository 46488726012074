import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton, Stack, TextField, Typography } from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { RootState } from "@store/store";
import { useGetBankAccountsApplicationQuery, usePostStatusApplicationMutation } from "@services/api/applications";
import { DialogType, setDialogConfig, setDialogConfigProcessing, ValidateDialogProps } from "@store/slices/appSlice";

import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";

enum FIELD_TYPE {
  CLIENT_NO,
  CONTRACT_NO,
  DISPERSION_DATE,
  NONE,
}

export const ResumeTab = () => {
  const dispatch = useDispatch();

  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);
  const bankAccounts = useGetBankAccountsApplicationQuery(applicationSelected.id ?? "0-0-0-0-0", {
    skip: applicationSelected.id === undefined,
  });

  const [triggerPostStatusApplication] = usePostStatusApplicationMutation();

  const [fieldEditing, setFieldEditing] = useState<FIELD_TYPE>(FIELD_TYPE.NONE);

  const handleOnEditClicked = (field: FIELD_TYPE) => {
    setFieldEditing(field);
  };

  const handleOnOkClicked = () => {
    setFieldEditing(FIELD_TYPE.NONE);
  };

  const handleOnCleanClicked = () => {
    setFieldEditing(FIELD_TYPE.NONE);
  };

  const handleOnToClientAccepted = async () => {
    dispatch(setDialogConfigProcessing(true));

    await triggerPostStatusApplication({
      applicationId: applicationSelected.id ?? "0-0-0-0-0",
      statusCode: "CSCLI",
      disableSequenceCheck: true,
    }).unwrap();

    dispatch(setDialogConfig({ shown: false, type: DialogType.NONE, props: undefined }));
  };

  const handleOnToClientRejected = () => {
    dispatch(setDialogConfig({ shown: false, type: DialogType.NONE, props: undefined }));
  };

  const handleOnToClientClicked = () => {
    dispatch(
      setDialogConfig({
        shown: true,
        type: DialogType.VALIDATE,
        onAcceptClick: handleOnToClientAccepted,
        onRejectClick: handleOnToClientRejected,
        props: { title: "Estas seguro de convertir a cliente:" } as ValidateDialogProps,
      }),
    );
  };

  return (
    <>
      <Typography variant="h4" fontWeight={600} color={"#002652"}>
        Resumen crédito
      </Typography>
      <Stack rowGap={2} mt={2}>
        <Typography variant="h4" color={"#002652"}>
          No. de cliente:{" "}
          {fieldEditing == FIELD_TYPE.CLIENT_NO ? (
            <TextField size="small"></TextField>
          ) : (
            <Typography component={"span"} variant="h4" color={"#528CD6"} fontWeight={"400"}>
              0000001
            </Typography>
          )}
          {fieldEditing != FIELD_TYPE.CLIENT_NO && (
            <IconButton size="small" onClick={() => handleOnEditClicked(FIELD_TYPE.CLIENT_NO)}>
              <EditIcon />
            </IconButton>
          )}
          {fieldEditing === FIELD_TYPE.CLIENT_NO && (
            <>
              <IconButton size="small" onClick={handleOnOkClicked}>
                <DoneIcon color="success" />
              </IconButton>
              <IconButton size="small" onClick={handleOnCleanClicked}>
                <ClearIcon color="error" />
              </IconButton>
            </>
          )}
        </Typography>

        <Typography variant="h4" color={"#002652"}>
          No. de contrato:{" "}
          {fieldEditing == FIELD_TYPE.CONTRACT_NO ? (
            <TextField size="small"></TextField>
          ) : (
            <Typography component={"span"} variant="h4" color={"#528CD6"} fontWeight={"400"}>
              XXXXXXXXXX-X
            </Typography>
          )}
          {fieldEditing != FIELD_TYPE.CONTRACT_NO && (
            <IconButton size="small" onClick={() => handleOnEditClicked(FIELD_TYPE.CONTRACT_NO)}>
              <EditIcon />
            </IconButton>
          )}
          {fieldEditing === FIELD_TYPE.CONTRACT_NO && (
            <>
              <IconButton size="small" onClick={handleOnOkClicked}>
                <DoneIcon color="success" />
              </IconButton>
              <IconButton size="small" onClick={handleOnCleanClicked}>
                <ClearIcon color="error" />
              </IconButton>
            </>
          )}
        </Typography>

        <Typography variant="h4" color={"#002652"}>
          Cuenta CLABE de Deposito:{" "}
          <Typography component={"span"} variant="h4" color={"#528CD6"} fontWeight={"400"}>
            {(bankAccounts.currentData?.length ?? 0) > 0
              ? bankAccounts.currentData?.at(0)?.clabe
              : "XXX XXX XXXXXXXXXXX X"}
          </Typography>{" "}
        </Typography>

        <Typography variant="h4" color={"#002652"}>
          Monto del crédito:{" "}
          <Typography component={"span"} variant="h4" color={"#528CD6"} fontWeight={"400"}>
            {applicationSelected.montoContratado}
          </Typography>
        </Typography>

        <Typography variant="h4" color={"#002652"}>
          Plazo del crédito:{" "}
          <Typography component={"span"} variant="h4" color={"#528CD6"} fontWeight={"400"}>
            {applicationSelected.plazoContratado} meses
          </Typography>
        </Typography>

        <Typography variant="h4" color={"#002652"}>
          Fecha de dispersión:{" "}
          {fieldEditing == FIELD_TYPE.DISPERSION_DATE ? (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
              <DatePicker
                // disablePast
                format="DD/MM/YYYY"
                label={"Fecha"}
                // value={dayjs(formik.values.date_visit)}
                slotProps={{
                  textField: {
                    required: true,
                    name: "dispersion_date",
                    size: "small",
                    // onBlur: formik.handleBlur,
                    // error: formik.touched.date_visit && Boolean(formik.errors.date_visit),
                    // helperText: formik.touched.date_visit ? formik.errors.date_visit : "",
                  },
                }}
                // onChange={(date) => formik.setFieldValue("date_visit", dayjs(date).format("YYYY-MM-DD"))}
              />
            </LocalizationProvider>
          ) : (
            <Typography component={"span"} variant="h4" color={"#528CD6"} fontWeight={"400"}>
              --/--/----
            </Typography>
          )}
          {fieldEditing != FIELD_TYPE.DISPERSION_DATE && (
            <IconButton size="small" onClick={() => handleOnEditClicked(FIELD_TYPE.DISPERSION_DATE)}>
              <EditIcon />
            </IconButton>
          )}
          {fieldEditing === FIELD_TYPE.DISPERSION_DATE && (
            <>
              <IconButton size="small" onClick={handleOnOkClicked}>
                <DoneIcon color="success" />
              </IconButton>
              <IconButton size="small" onClick={handleOnCleanClicked}>
                <ClearIcon color="error" />
              </IconButton>
            </>
          )}
        </Typography>
        <Button variant="contained" color="success" sx={{ alignSelf: "end" }} onClick={handleOnToClientClicked}>
          Convertir a Cliente
        </Button>
      </Stack>
    </>
  );
};
