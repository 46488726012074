import { formatCurrency } from "@helpers/formats";
import { Typography, Divider, Grid2 } from "@mui/material";
import { useGetCompanyProfileQuery } from "@services/api/applications";
import { RootState } from "@store/store";
import { useSelector } from "react-redux";

export const ProfileTab = () => {
  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  const companyProfile = useGetCompanyProfileQuery(
    { rfc: applicationSelected.rfc, loanApplicationId: applicationSelected.id ?? "0-0-0-0-0" },
    {
      skip: applicationSelected.rfc === undefined,
    },
  );

  const createRowTable = (t1: string, v1: string, t2: string, v2: string) => {
    return (
      <>
        <Grid2 size={{ xs: 6 }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}>
          <div>
            <Grid2 container>
              <Grid2 size={{ xs: 6 }}>
                <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                  {t1}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 6 }}>
                <Typography color={"#002652"}>{v1}</Typography>
              </Grid2>
            </Grid2>
          </div>
        </Grid2>
        <Grid2
          size={{ xs: 6 }}
          p={1}
          borderLeft={"1px solid rgba(163, 212, 232, 0.5)"}
          borderBottom={"1px solid rgba(163, 212, 232, 0.5)"}
        >
          <div>
            <Grid2 container ml={4}>
              <Grid2 size={{ xs: 6 }}>
                <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                  {t2}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 6 }}>
                <Typography color={"#002652"}>{v2}</Typography>
              </Grid2>
            </Grid2>
          </div>
        </Grid2>
      </>
    );
  };

  const createSimpleRow = (t1: string, v1: string, cols: Array<number> = [3, 9]) => {
    return (
      <>
        <Grid2 size={{ xs: cols[0] }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}>
          <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
            {t1}
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: cols[1] }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}>
          <Typography color={"#002652"}>{v1}</Typography>
        </Grid2>
      </>
    );
  };

  return (
    <>
      <Typography variant="h3" fontWeight={600} color={"#002652"}>
        Perfil Empresa
      </Typography>

      <Divider sx={{ backgroundColor: "rgba(163, 212, 232, 0.5)", my: 2 }} />

      <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
        Modelo
      </Typography>

      <Grid2 container>
        {createSimpleRow("Resultado", companyProfile.currentData?.model_data.score_code ?? "")}
        {createSimpleRow(
          "Calificación",
          formatCurrency(Number(companyProfile.currentData?.model_data.score) ?? 0, "", 1),
        )}
        {createSimpleRow(
          "Monto Autorizado",
          formatCurrency(parseFloat(companyProfile.currentData?.model_data.authorized_amount ?? "0"), "$", 0),
        )}
        {createSimpleRow("Plan Autorizado", `${companyProfile.currentData?.model_data.authorized_term ?? 0} meses`)}
        {createSimpleRow(
          "Tasa",
          `${formatCurrency(companyProfile.currentData?.model_data.interest_rate ?? 0, "", 1)}%`,
        )}
      </Grid2>

      <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
        Generales
      </Typography>
      <Grid2 container>
        {createSimpleRow("Nombre Comercial", companyProfile.currentData?.general_data?.company_name ?? "")}
        {createSimpleRow("Pagina web", companyProfile.currentData?.general_data?.web_page ?? "")}
        {createRowTable(
          "Linkedin",
          companyProfile.currentData?.general_data?.linkedin ?? "",
          "Facebook",
          companyProfile.currentData?.general_data?.facebook ?? "",
        )}
        {createRowTable(
          "Twitter/X",
          companyProfile.currentData?.general_data?.x_twitter ?? "",
          "Instagram",
          companyProfile.currentData?.general_data?.instagram ?? "",
        )}
      </Grid2>

      <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
        Perfil Empresa
      </Typography>

      <Grid2 container>
        {createRowTable(
          "Ventas Anuales Facturadas",
          formatCurrency(
            parseFloat(companyProfile.currentData?.general_company_details.annual_sales_invoiced ?? "0"),
            "$",
            0,
          ),
          "Compras Anuales Facturadas",
          formatCurrency(
            parseFloat(companyProfile.currentData?.general_company_details.annual_expenses_invoiced ?? "0"),
            "$",
            0,
          ),
        )}
        {createRowTable(
          "Ingresos Anuales Facturadas",
          formatCurrency(
            parseFloat(companyProfile.currentData?.general_company_details.annual_revenues_invoiced ?? "0"),
            "$",
            0,
          ),
          "Egresos Anuales Facturadas",
          formatCurrency(
            parseFloat(companyProfile.currentData?.general_company_details.annual_expenses_invoiced ?? "0"),
            "$",
            0,
          ),
        )}
        {createRowTable(
          "Número de empleados",
          companyProfile.currentData?.general_company_details.number_employees ?? "0",
          "Nómina Mensual",
          formatCurrency(
            parseFloat(companyProfile.currentData?.general_company_details.monthly_payroll ?? "0"),
            "$",
            0,
          ),
        )}
        {createRowTable(
          "Score Pyme (Empresa)",
          formatCurrency(parseFloat(companyProfile.currentData?.general_company_details.score_company ?? "0"), "$", 0),
          "BC Score (Persona)",
          formatCurrency(parseFloat(companyProfile.currentData?.general_company_details.bc_score ?? "0"), "$", 0),
        )}
        {createSimpleRow("Antigüedad", companyProfile.currentData?.general_company_details.seniority ?? "0")}
        {createSimpleRow(
          "Actividad Preponderante (%)",
          companyProfile.currentData?.general_company_details.predominant_activity ?? "",
        )}
        {createSimpleRow("Uso del crédito", companyProfile.currentData?.credit_use ?? "")}
      </Grid2>

      <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
        Categorías de Análisis
      </Typography>
      <Grid2 container>
        {createSimpleRow(
          "Ventas, Compras, Flujos",
          `${formatCurrency(Number(companyProfile.currentData?.analysis_categories.sales_purchases_flow) ?? 0, "")}%`,
          [4, 8],
        )}
        {createSimpleRow(
          "Clientes, Proveedores, P&S",
          `${formatCurrency(Number(companyProfile.currentData?.analysis_categories.customers_suppliers_ps) ?? 0, "")}%`,
          [4, 8],
        )}
        {createSimpleRow(
          "Nómina y empleados",
          `${formatCurrency(Number(companyProfile.currentData?.analysis_categories.payroll_employees) ?? 0, "")}%`,
          [4, 8],
        )}
        {createSimpleRow(
          "Financieros Declarados",
          `${formatCurrency(Number(companyProfile.currentData?.analysis_categories.financial_declared) ?? 0, "")}%`,
          [4, 8],
        )}
        {createSimpleRow(
          "Buró Acreditado",
          `${formatCurrency(Number(companyProfile.currentData?.analysis_categories.accredited_bureau) ?? 0, "")}%`,
          [4, 8],
        )}
        {createSimpleRow(
          "Buró Persona",
          `${formatCurrency(Number(companyProfile.currentData?.analysis_categories.bureau_person) ?? 0, "")} %`,
          [4, 8],
        )}
        {createSimpleRow(
          "CSF & Otros",
          `${formatCurrency(Number(companyProfile.currentData?.analysis_categories.csf_others) ?? 0, "")}%`,
          [4, 8],
        )}
        <Grid2 size={{ xs: 4 }} p={1}>
          <Typography variant="body1" color={"#002652D9"} fontWeight={700}>
            Total:
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 8 }} p={1}>
          <Typography color={"#002652D9"} fontWeight={600}>
            {`${formatCurrency(Number(companyProfile.currentData?.analysis_categories.total) ?? 0, "")} %`}
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          <Divider sx={{ backgroundColor: "rgba(163, 212, 232, 0.5)", my: 1 }} />
        </Grid2>
      </Grid2>
    </>
  );
};
