import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid2,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { UUID } from "crypto";
import dayjs from "dayjs";
import * as yup from "yup";
import { useFormik } from "formik";

import { RootState } from "@store/store";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { CustomModal } from "@components/CustomModal";
import { setApplicationCompleted } from "@store/slices/appSlice";
import { MissingDocsSegment } from "@components/MissingDocsSegment";
import { ShareholderStructure } from "@components/ShareholderStructure";
import { ValidateDialogSegment } from "@components/ValidateDialogSegment";

import { useGetPersonsQuery, usePatchPersonMutation, usePostPersonMutation } from "@services/api/persons";
import { useGetAccountQuery, usePatchAccountMutation } from "@services/api/accounts";
import {
  useGetFileCategoriesQuery,
  useGetPersonTypesQuery,
  useGetStatesQuery,
  useLazyGetCitiesByStateQuery,
} from "@services/api/catalogs";
import {
  useDeleteDocumentMutation,
  useGetUrlToDownloadDocMutation,
  useLazyGetDocumentsQuery,
  useLazyGetSignUrlToUploadDocQuery,
  usePatchDocumentMutation,
  usePostDocumentMutation,
  usePostGenerateDocMutation,
  usePostUploadFileMutation,
} from "@services/api/documents";

import SeeIcon from "@assets/see_icon.svg";
import iconUp from "@assets/upload_icon.svg";
import BackIcon from "@assets/back_icon.svg";
import TrashIcon from "@assets/trash_icon.svg";
import DownloadIcon from "@assets/download_icon.svg";

import { DocumentInfo } from "@interfaces/documents";
import { generateDummyDocuments, getMimeTypeFromFilename } from "@helpers/generals";
import { Person } from "@interfaces/persons";
import { usePostStatusApplicationMutation, usePutApplicationsMutation } from "@services/api/applications";

const initialFormikValues = {
  publicDeedNumber: "",
  publicDeedDate: null,
  notaryOfficeNumber: "",
  notaryName: "",
  municipality: "",
  federative: "",
  sufficientPowers: null,
  limitationContractCredit: null,
  legalAgentName: "",
  legalAgentLastName: "",
  legalAgentLastName2: "",
  commissionerName: "",
  commissionerLastName: "",
  commissionerLastName2: "",
  majorityShareholderValidate: null,
};

export const Dictamen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isDragging, setIsDragging] = useState(false);
  const [legalPersonId, setLegalPersonId] = useState<UUID>();
  const [childrenModal, setChildrenModal] = useState<React.Children>();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [majorityShareholder, setMajorityShareHolder] = useState<Person | undefined>();
  const [docDictamenActive, setDocDictamenActive] = useState<DocumentInfo>();

  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  const statesPlaces = useGetStatesQuery();

  const personTypes = useGetPersonTypesQuery();
  const persons = useGetPersonsQuery(
    { q: `loan_application_id:${applicationSelected.id}` },
    { skip: !personTypes.isSuccess },
  );

  const docTypes = useGetFileCategoriesQuery();
  const accountDetails = useGetAccountQuery(JSON.parse(applicationSelected.fullData)["client_details_id"]);

  const [triggerPostPerson] = usePostPersonMutation();
  const [triggerPatchPerson] = usePatchPersonMutation();
  const [triggetPatchAccount] = usePatchAccountMutation();
  const [triggerPostDocument] = usePostDocumentMutation();
  const [triggerPatchDocument] = usePatchDocumentMutation();
  const [triggerPostUploadDoc] = usePostUploadFileMutation();
  const [triggerDeleteDocument] = useDeleteDocumentMutation();
  const [triggerPutApplication] = usePutApplicationsMutation();
  const [triggerPostGenerateDoc] = usePostGenerateDocMutation();
  const [triggerPostUrlDocument] = useGetUrlToDownloadDocMutation();
  const [triggetPostUrlToUploadDoc] = useLazyGetSignUrlToUploadDocQuery();
  const [triggerPostStatusApplication] = usePostStatusApplicationMutation();

  const [triggerGetDocuments, resultGetDocuments] = useLazyGetDocumentsQuery();
  const [triggerGetCitiesByState, resultCities] = useLazyGetCitiesByStateQuery();

  const formik = useFormik({
    initialValues: initialFormikValues,
    validationSchema: yup.object().shape({
      publicDeedNumber: yup.string().required("Campo requerido"),
      publicDeedDate: yup.date().required("Campo requerido"),
      notaryOfficeNumber: yup.string().required("Campo requerido"),
      notaryName: yup.string().required("Campo requerido"),
      municipality: yup.number().required("Campo requerido"),
      federative: yup.number().required("Campo requerido"),
      sufficientPowers: yup.boolean().notRequired(),
      limitationContractCredit: yup.boolean().notRequired(),
      legalAgentName: yup.string().required("Campo requerido"),
      legalAgentLastName: yup.string().required("Campo requerido"),
      legalAgentLastName2: yup.string().notRequired(),
      commissionerName: yup.string().required("Campo requerido"),
      commissionerLastName: yup.string().required("Campo requerido"),
      commissionerLastName2: yup.string().notRequired(),
      majorityShareholderValidate: yup.boolean().notRequired(),
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        try {
          await triggetPatchAccount({
            clientDetailsId: JSON.parse(applicationSelected.fullData)["client_details_id"],
            body: {
              public_deed_number: values.publicDeedNumber,
              public_deed_date: values.publicDeedDate,
              notary_office_number: values.notaryOfficeNumber,
              full_name_notary: values.notaryName,
              municipality: values.municipality,
              federative_organization: values.federative,
              sufficient_powers: values.sufficientPowers,
              limitation_contract_credit: values.limitationContractCredit,
              commissioner_name: values.commissionerName,
              commissioner_last_name: values.commissionerLastName,
              commissioner_last_name_2: values.commissionerLastName2,
            },
          }).unwrap();

          if (legalPersonId) {
            await triggerPatchPerson({
              personId: legalPersonId ?? "0-0-0-0-0",
              body: {
                name: values.legalAgentName,
                last_name: values.legalAgentLastName,
                last_name_2: values.legalAgentLastName2,
              },
            });
          } else {
            await triggerPostPerson({
              name: values.legalAgentName,
              last_name: values.legalAgentLastName,
              last_name_2: values.legalAgentLastName2,
              person_type_id: personTypes.currentData?.find((type) => type.code === "PTRL")?.id ?? "0-0-0-0-0",
              loan_application_id: applicationSelected.id ?? "0-0-0-0-0",
              percent: 0,
              total_value: 0,
            });
          }

          await triggerPatchDocument({
            applicationId: applicationSelected.id,
            documentId: docDictamenActive?.id ?? "0-0-0-0-0",
            body: {
              validated: true,
            },
          }).unwrap();

          dispatch(setApplicationCompleted(true));

          await triggerPutApplication({
            applicationId: applicationSelected.id,
            body: {
              decision_validated: true,
            },
          }).unwrap();

          await triggerPostStatusApplication({
            applicationId: applicationSelected.id ?? "0-0-0-0-0",
            statusCode: "CSDIC",
            disableSequenceCheck: true,
          }).unwrap();

          if (applicationSelected.existsSDC) {
            await triggerPostGenerateDoc({
              applicationId: applicationSelected.id ?? "0-0-0-0-0",
              documentType: "solicitud_credito",
            }).unwrap();
          }
        } catch (error) {
          console.log("Error: %s", error);
        }
      } else {
        console.log("Datos no validos: %s", JSON.stringify(formik.errors));
      }
    },
  });

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);

    const files = event.dataTransfer.files;
    console.log(files);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const tmpFile = files?.[0];
    const dummy = generateDummyDocuments(
      1,
      docTypes.currentData?.find((category) => category.code === "CDDTN"),
    )[0];

    try {
      if (dummy.id === undefined) {
        dummy.client_details_id = JSON.parse(applicationSelected.fullData)["client_details_id"];
      }

      const urlToUploadResponse = await triggetPostUrlToUploadDoc({
        upload_type: "client",
        file_name: tmpFile?.name ?? "",
        regimen: "pm",
        client_details_id: dummy.client_details_id,
        file_category_id: dummy.file_category_id,
        loan_application_id: applicationSelected.id,
      }).unwrap();

      const signUrl = urlToUploadResponse.data;

      const tmp = new FormData();

      tmp.append("key", signUrl.fields.key);
      tmp.append("AWSAccessKeyId", signUrl.fields.AWSAccessKeyId);
      tmp.append("x-amz-security-token", signUrl.fields["x-amz-security-token"]);
      tmp.append("policy", signUrl.fields.policy);
      tmp.append("signature", signUrl.fields.signature);
      tmp.append("file", tmpFile);

      await triggerPostUploadDoc({
        url: signUrl.url,
        body: tmp,
      }).unwrap();

      const last = resultGetDocuments.currentData?.find((item) => item.status);

      if (last !== undefined) {
        await triggerPatchDocument({
          applicationId: applicationSelected.id,
          documentId: last?.id ?? "0-0-0-0-0",
          body: {
            status: false,
          },
        }).unwrap();
      }

      await triggerPostDocument({
        loan_application_id: applicationSelected.id ?? "0-0-0-0-0",
        file_category_id: dummy.file_category_id ?? "0-0-0-0-0",
        url: signUrl.fields.key,
        file_name: tmpFile?.name ?? "",
        client_details_id: dummy.client_details_id,
      }).unwrap();
    } catch (error) {
    } finally {
      tmpFile.value = "";
      // await triggerGetDocuments(applicationSelected.id);

      await triggerGetDocuments({
        file_category_id: docTypes.currentData?.find((item) => item.code === "CDDTN")?.id,
        loan_application_id: applicationSelected.id,
      });
    }
  };

  const handleOnClickValidateAndComplete = async () => {
    setChildrenModal(
      <ValidateDialogSegment
        type={"COMPLETE"}
        title={"Estas seguro de completar y validar el dictamen de:"}
        onBackClick={handleOnBackClicked}
        onContinueClick={handleOnContinueClicked}
      />,
    );
    setShowConfirmModal(true);
  };

  const handleOnClickBack = (event) => {
    event.preventDefault();
    navigate("/expediente");
  };

  const handleOnBackClicked = () => {
    setShowConfirmModal(false);
  };

  const handleOnContinueClicked = async () => {
    setShowConfirmModal(false);
    formik.submitForm();
  };

  const handleOnMissingFilesClicked = () => {
    setShowConfirmModal(false);
  };

  const handleOnMissingDocsClicked = () => {
    setChildrenModal(
      <MissingDocsSegment onBackClick={handleOnBackClicked} onContinueClick={handleOnMissingFilesClicked} />,
    );
    setShowConfirmModal(true);
  };

  const handleOnSeeClicked = async (dictamenFile: DocumentInfo) => {
    const urlDownload = await triggerPostUrlDocument(dictamenFile.url);

    const response = await fetch(urlDownload.data ?? "");
    const blob = await response.blob();

    const url = window.URL.createObjectURL(new Blob([blob], { type: getMimeTypeFromFilename(dictamenFile.file_name) }));
    window.open(url);
  };

  const handleOnDownloadClicked = async (dictamenFile: DocumentInfo) => {
    const urlDownload = await triggerPostUrlDocument(dictamenFile.url);
    const response = await fetch(urlDownload.data ?? "");
    const blob = await response.blob();

    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("target", "_blank");
    link.setAttribute("download", dictamenFile.file_name);

    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  const handleOnDeleteClicked = async (dictamenFile: DocumentInfo) => {
    await triggerDeleteDocument(dictamenFile.id ?? "0-0-0-0-0");

    await triggerGetDocuments({
      file_category_id: docTypes.currentData?.find((item) => item.code === "CDDTN")?.id,
      loan_application_id: applicationSelected.id,
    });
  };

  useEffect(() => {
    if (formik.values.federative != "") {
      triggerGetCitiesByState(Number(formik.values.federative));
    }
  }, [formik.values.federative]);

  useEffect(() => {
    if (persons.isSuccess && persons.currentData) {
      const typePTRL = personTypes.currentData?.find((type) => type.code === "PTRL");
      const legalPerson = persons.currentData.find((person) => person.person_type_id === typePTRL?.id);

      setLegalPersonId(legalPerson?.id);

      formik.setFieldValue("legalAgentName", legalPerson?.name ?? "", false);
      formik.setFieldValue("legalAgentLastName", legalPerson?.last_name ?? "", false);
      formik.setFieldValue("legalAgentLastName2", legalPerson?.last_name_2 ?? "", false);

      const typePTAM = personTypes.currentData?.find((type) => type.code === "PTAM");
      const majorityShareholder = persons.currentData?.find((person) => person.person_type_id === typePTAM?.id);

      setMajorityShareHolder(majorityShareholder);
    }
  }, [persons]);

  useEffect(() => {
    if (accountDetails.isSuccess && accountDetails.currentData) {
      formik.setFieldValue("publicDeedNumber", accountDetails.currentData.data.public_deed_number ?? "", true);
      formik.setFieldValue("publicDeedDate", accountDetails.currentData.data.public_deed_date ?? "", true);
      formik.setFieldValue("notaryOfficeNumber", accountDetails.currentData.data.notary_office_number ?? "", true);
      formik.setFieldValue("notaryName", accountDetails.currentData.data.full_name_notary ?? "", true);
      formik.setFieldValue("municipality", accountDetails.currentData.data.municipality ?? "", false);
      formik.setFieldValue("federative", accountDetails.currentData.data.federative_organization ?? "", false);
      formik.setFieldValue("sufficientPowers", accountDetails.currentData.data.sufficient_powers, true);
      formik.setFieldValue(
        "limitationContractCredit",
        accountDetails.currentData.data.limitation_contract_credit,
        true,
      );
      formik.setFieldValue("commissionerName", accountDetails.currentData.data.commissioner_name ?? "", true);
      formik.setFieldValue("commissionerLastName", accountDetails.currentData.data.commissioner_last_name ?? "", true);
      formik.setFieldValue(
        "commissionerLastName2",
        accountDetails.currentData.data.commissioner_last_name_2 ?? "",
        true,
      );
    }
  }, [accountDetails]);

  useEffect(() => {
    if (docTypes.isSuccess) {
      triggerGetDocuments({
        file_category_id: docTypes.currentData?.find((item) => item.code === "CDDTN")?.id,
        loan_application_id: applicationSelected.id,
      });
    }
  }, [docTypes]);

  useEffect(() => {
    if (resultGetDocuments.isSuccess) {
      const docDictamenActive = resultGetDocuments.data.find((doc) => doc.status === true);
      setDocDictamenActive(docDictamenActive);
    }
  }, [resultGetDocuments]);

  return (
    <>
      <Box my={2} mb={5} pb={4}>
        <Grid2 container>
          <Grid2>
            <Button
              variant="text"
              startIcon={<img src={BackIcon} />}
              sx={{
                color: "#F2704F",
                px: 1,
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                },
              }}
              onClick={handleOnClickBack}
            >
              Regresar a expediente
            </Button>
          </Grid2>
        </Grid2>
        <Typography variant={"h4"} color={"#002652"} fontWeight={300}>
          {applicationSelected.razonSocial}
        </Typography>

        <Typography variant={"h4"} color={"#002652"} fontWeight={300}>
          ID SOLICITUD:{" "}
          <Typography variant="h4" component={"span"} color={"#002652"} fontWeight={600}>
            {applicationSelected.solicitudId}
          </Typography>
        </Typography>

        <Container sx={{ marginTop: 5 }}>
          <Typography fontSize={"25px"} color={"#002652"} fontWeight={600}>
            Dictamen
          </Typography>

          <form onSubmit={formik.handleSubmit} noValidate>
            <Typography fontSize={"14px"} color={"#002652"} fontWeight={700} mt={3}>
              Datos acta constitutiva
            </Typography>
            <Grid2 container spacing={1} mb={3}>
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                <TextField
                  id="publicDeedNumber"
                  name="publicDeedNumber"
                  label="No. de escritura pública"
                  margin="normal"
                  fullWidth
                  required
                  slotProps={{
                    htmlInput: {
                      maxLength: 30,
                    },
                  }}
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.publicDeedNumber}
                  error={formik.touched.publicDeedNumber && Boolean(formik.errors.publicDeedNumber)}
                  helperText={formik.touched.publicDeedNumber && formik.errors.publicDeedNumber}
                  disabled={applicationSelected.dictamenCompletado}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                <FormControl fullWidth margin="normal">
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                    <DatePicker
                      format="DD/MM/YYYY"
                      label="Fecha de escritura pública"
                      value={formik.values.publicDeedDate ? dayjs(formik.values.publicDeedDate) : null}
                      onChange={(date) => formik.setFieldValue("publicDeedDate", dayjs(date).format("YYYY-MM-DD"))}
                      slotProps={{
                        textField: {
                          required: true,
                          name: "publicDeedDate",
                          onBlur: formik.handleBlur,
                        },
                      }}
                      disabled={applicationSelected.dictamenCompletado}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid2>
            </Grid2>

            <Typography fontSize={"14px"} color={"#002652"} fontWeight={700} mt={3}>
              Datos de notaría
            </Typography>
            <Grid2 container spacing={1}>
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                <TextField
                  id="notaryOfficeNumber"
                  name="notaryOfficeNumber"
                  label="No. de Notaría"
                  margin="normal"
                  fullWidth
                  required
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.notaryOfficeNumber && Boolean(formik.errors.notaryOfficeNumber)}
                  helperText={formik.touched.notaryOfficeNumber && formik.errors.notaryOfficeNumber}
                  value={formik.values.notaryOfficeNumber}
                  disabled={applicationSelected.dictamenCompletado}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                <TextField
                  id="notaryName"
                  name="notaryName"
                  label="Nombre completo notario"
                  margin="normal"
                  fullWidth
                  required
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.notaryName && Boolean(formik.errors.notaryName)}
                  helperText={formik.touched.notaryName && formik.errors.notaryName}
                  value={formik.values.notaryName}
                  disabled={applicationSelected.dictamenCompletado}
                />
              </Grid2>
            </Grid2>
            <Grid2 container spacing={1} mb={3}>
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                <FormControl fullWidth margin="normal">
                  <InputLabel required>Entidad federativa</InputLabel>
                  <Select
                    id="federative"
                    name="federative"
                    label={"Entidad federativa"}
                    value={statesPlaces.isSuccess ? formik.values.federative : ""}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                    disabled={applicationSelected.dictamenCompletado}
                  >
                    {statesPlaces.isSuccess &&
                      statesPlaces.currentData?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {formik.touched.federative && Boolean(formik.errors.federative) && (
                    <FormHelperText>{formik.errors.federative}</FormHelperText>
                  )}
                </FormControl>
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                <FormControl fullWidth margin="normal">
                  <InputLabel required>Municipio</InputLabel>
                  <Select
                    id="municipality"
                    name="municipality"
                    label="Municipio"
                    value={resultCities.isSuccess ? formik.values.municipality : ""}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                    disabled={applicationSelected.dictamenCompletado}
                  >
                    {resultCities.isSuccess &&
                      resultCities.currentData?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {formik.touched.municipality && Boolean(formik.errors.municipality) && (
                    <FormHelperText>{formik.errors.municipality}</FormHelperText>
                  )}
                </FormControl>
              </Grid2>
            </Grid2>

            <Typography fontSize={"14px"} color={"#002652"} fontWeight={700} mt={3}>
              Representante legal
            </Typography>
            <Grid2 container spacing={1} mb={3}>
              <Grid2 size={{ xs: 12, sm: 12, md: 4 }}>
                <TextField
                  id="legalAgentName"
                  name="legalAgentName"
                  label="Nombre(s)"
                  margin="normal"
                  fullWidth
                  autoComplete="off"
                  required
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.legalAgentName && Boolean(formik.errors.legalAgentName)}
                  helperText={formik.touched.legalAgentName && formik.errors.legalAgentName}
                  value={formik.values.legalAgentName}
                  disabled={applicationSelected.dictamenCompletado}
                />
              </Grid2>

              <Grid2 size={{ xs: 12, sm: 12, md: 4 }}>
                <TextField
                  id="legalAgentLastName"
                  name="legalAgentLastName"
                  label="Apellido paterno"
                  margin="normal"
                  fullWidth
                  autoComplete="off"
                  required
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.legalAgentLastName && Boolean(formik.errors.legalAgentLastName)}
                  helperText={formik.touched.legalAgentLastName && formik.errors.legalAgentLastName}
                  value={formik.values.legalAgentLastName}
                  disabled={applicationSelected.dictamenCompletado}
                />
              </Grid2>

              <Grid2 size={{ xs: 12, sm: 12, md: 4 }}>
                <TextField
                  id="legalAgentLastName2"
                  name="legalAgentLastName2"
                  label="Apellido materno"
                  margin="normal"
                  fullWidth
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.legalAgentLastName2 && Boolean(formik.errors.legalAgentLastName2)}
                  helperText={formik.touched.legalAgentLastName2 && formik.errors.legalAgentLastName2}
                  value={formik.values.legalAgentLastName2}
                  disabled={applicationSelected.dictamenCompletado}
                />
              </Grid2>
            </Grid2>
            <Grid2 container spacing={1} mb={3}>
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                <Box
                  sx={{
                    mx: { xs: 0, sm: 4 },
                  }}
                >
                  <FormControl disabled={applicationSelected.dictamenCompletado}>
                    <FormLabel>Poderes suficientes:</FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="sufficientPowers"
                            checked={formik.values.sufficientPowers === true}
                            onBlur={formik.handleBlur}
                            onChange={() => formik.setFieldValue("sufficientPowers", true)}
                          />
                        }
                        label="Sí"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="sufficientPowers"
                            checked={formik.values.sufficientPowers === false}
                            onBlur={formik.handleBlur}
                            onChange={() => formik.setFieldValue("sufficientPowers", false)}
                          />
                        }
                        label="No"
                      />
                    </FormGroup>
                    <FormHelperText>{formik.errors.sufficientPowers}</FormHelperText>
                  </FormControl>
                </Box>
              </Grid2>

              <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                <FormControl disabled={applicationSelected.dictamenCompletado}>
                  <FormLabel>Limitante para contratar créditos:</FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="limitationContractCredit"
                          checked={formik.values.limitationContractCredit === true}
                          onBlur={formik.handleBlur}
                          onChange={() => formik.setFieldValue("limitationContractCredit", true)}
                        />
                      }
                      label="Sí"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="limitationContractCredit"
                          checked={formik.values.limitationContractCredit === false}
                          onBlur={formik.handleBlur}
                          onChange={() => formik.setFieldValue("limitationContractCredit", false)}
                        />
                      }
                      label="No"
                    />
                  </FormGroup>
                  <FormHelperText>{formik.errors.limitationContractCredit}</FormHelperText>
                </FormControl>
              </Grid2>
            </Grid2>

            <Typography fontSize={"14px"} color={"#002652"} fontWeight={700} mt={3}>
              Comisario:
            </Typography>
            <Grid2 container spacing={1} mb={3}>
              <Grid2 size={{ xs: 12, sm: 12, md: 4 }}>
                <TextField
                  id="commissionerName"
                  name="commissionerName"
                  label="Nombre(s)"
                  margin="normal"
                  fullWidth
                  autoComplete="off"
                  required
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.commissionerName && Boolean(formik.errors.commissionerName)}
                  helperText={formik.touched.commissionerName && formik.errors.commissionerName}
                  value={formik.values.commissionerName}
                  disabled={applicationSelected.dictamenCompletado}
                />
              </Grid2>

              <Grid2 size={{ xs: 12, sm: 12, md: 4 }}>
                <TextField
                  id="commissionerLastName"
                  name="commissionerLastName"
                  label="Apellido paterno"
                  margin="normal"
                  fullWidth
                  autoComplete="off"
                  required
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.commissionerLastName && Boolean(formik.errors.commissionerLastName)}
                  helperText={formik.touched.commissionerLastName && formik.errors.commissionerLastName}
                  value={formik.values.commissionerLastName}
                  disabled={applicationSelected.dictamenCompletado}
                />
              </Grid2>

              <Grid2 size={{ xs: 12, sm: 12, md: 4 }}>
                <TextField
                  id="commissionerLastName2"
                  name="commissionerLastName2"
                  label="Apellido materno"
                  margin="normal"
                  fullWidth
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.commissionerLastName2 && Boolean(formik.errors.commissionerLastName2)}
                  helperText={formik.touched.commissionerLastName2 && formik.errors.commissionerLastName2}
                  value={formik.values.commissionerLastName2}
                  disabled={applicationSelected.dictamenCompletado}
                />
              </Grid2>
            </Grid2>

            <Typography variant="body1" color={"#002652"} fontWeight={700} mt={3}>
              Accionista Mayoritario proporcionado en Onboarding
            </Typography>
            <Grid2 container mt={3}>
              <Grid2>
                <Typography variant="body1" color="#528CD6" fontWeight={400} minWidth={"400px"}>
                  {majorityShareholder !== undefined &&
                    [majorityShareholder.name, majorityShareholder.last_name, majorityShareholder.last_name_2].join(
                      " ",
                    )}
                </Typography>
              </Grid2>
              <Grid2>
                <Grid2 container>
                  <Grid2 alignContent={"center"} pr={2}>
                    <Typography>Correcto:</Typography>
                  </Grid2>
                  <Grid2>
                    <FormControl disabled={applicationSelected.dictamenCompletado}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="majorityShareholderValidate"
                              checked={formik.values.majorityShareholderValidate === true}
                              onBlur={formik.handleBlur}
                              onChange={() => formik.setFieldValue("majorityShareholderValidate", true)}
                            />
                          }
                          label="Sí"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="majorityShareholderValidate"
                              checked={formik.values.majorityShareholderValidate === false}
                              onBlur={formik.handleBlur}
                              onChange={() => formik.setFieldValue("majorityShareholderValidate", false)}
                            />
                          }
                          label="No"
                        />
                      </FormGroup>
                      <FormHelperText>{formik.errors.majorityShareholderValidate}</FormHelperText>
                    </FormControl>
                  </Grid2>
                </Grid2>
              </Grid2>
            </Grid2>
          </form>

          <Box mb={4} height={"100%"}>
            <ShareholderStructure />
          </Box>
          <Box mt={4} sx={{ paddingBottom: 5 }}>
            <Typography fontSize={"25px"} color={"#002652"} fontWeight={600} mt={3} mb={3}>
              Documentación dictamen
            </Typography>
            <Grid2 container spacing={1} mb={3}>
              <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
                <Box
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  sx={{
                    border: "2px dashed #A3D4E8",
                    borderRadius: "20px",
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                    padding: "20px",
                    textAlign: "center",
                    maxWidth: "310px",
                    margin: "auto",
                    backgroundColor: isDragging ? "#f7f7f7" : "white",
                    transition: "background-color 0.3s",
                    "&:hover": {
                      backgroundColor: "#f7f7f7",
                    },
                  }}
                >
                  <input type="file" style={{ display: "none" }} id="file-upload" onChange={handleFileSelect} />

                  <label htmlFor="file-upload">
                    <Box component="img" src={iconUp} alt="logo" mb={2} sx={{ cursor: "pointer" }} />
                  </label>

                  <Typography fontSize={"20px"} color={"#002652"} fontWeight={400}>
                    Arrastra el archivo del dictamen aquí o{" "}
                    <label
                      htmlFor="file-upload"
                      style={{ cursor: "pointer", color: "#528CD6", textDecoration: "underline" }}
                    >
                      {" "}
                      selecciona{" "}
                    </label>
                    desde tu computadora
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
                <Typography fontSize={"14px"} color={"#528CD6"} fontWeight={700}>
                  Documentos cargados
                </Typography>
                {resultGetDocuments.isSuccess &&
                  resultGetDocuments.data?.map((item, index) => (
                    <Box display="flex" alignItems="center" p={1} borderBottom={1} key={index}>
                      <Typography variant="body2" color={"#002652"} fontWeight={700} style={{ flex: 1 }}>
                        Documento {index + 1}: <span style={{ fontWeight: 300 }}>{item.file_name}</span>
                      </Typography>
                      <IconButton onClick={() => handleOnSeeClicked(item)}>
                        <img src={SeeIcon} />
                      </IconButton>

                      <IconButton onClick={() => handleOnDownloadClicked(item)}>
                        <img src={DownloadIcon} />
                      </IconButton>

                      <IconButton onClick={() => handleOnDeleteClicked(item)}>
                        <img src={TrashIcon} />
                      </IconButton>
                    </Box>
                  ))}
              </Grid2>
            </Grid2>
          </Box>
        </Container>
        <Grid2 container columnSpacing={2} display={"flex"} flexDirection={"row"} justifyContent={"end"}>
          <Grid2>
            <Button variant="orange" sx={{ background: "#FF9900" }} onClick={handleOnMissingDocsClicked}>
              Documentos faltantes
            </Button>
          </Grid2>
          <Grid2>
            <Button
              variant="contained"
              sx={{ mr: 2, background: "#00A024" }}
              disabled={applicationSelected.dictamenCompletado}
              onClick={handleOnClickValidateAndComplete}
            >
              Completar y validar
            </Button>
          </Grid2>
        </Grid2>
      </Box>
      <CustomModal open={showConfirmModal} children={childrenModal} />
    </>
  );
};
