import { useEffect, useMemo, useState } from "react";
import { Alert, Box, Button, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

import { OnSiteVisit } from "./OnSiteVisit";
import { CustomModal } from "./CustomModal";
import { CustomTabPanel } from "./CustomTabs";
import { CustomVerticalTabPanel } from "./CustomVerticalTabs";
import { ResumeTab } from "./CompanyFileTabSegments/ResumeTab";
import { DocumentAction, FileSectionV2 } from "./FileSectionV2";
import { ValidateDialogSegment } from "./ValidateDialogSegment";
import { ProfileTab } from "./CompanyFileTabSegments/ProfileTab";
import { ContractDocsTab } from "./CompanyFileTabSegments/ContractDocsTab";
import { RejectDocumentDialogSegment } from "./RejectDocumentDialogSegment";

import { Person } from "@interfaces/persons";
import { RootState, store } from "@store/store";
import { DocumentInfo } from "@interfaces/documents";
import { generateDummyDocuments, getMimeTypeFromFilename } from "@helpers/generals";
import {
  useGetUrlToDownloadDocMutation,
  useLazyGetDocumentsByAppQuery,
  useLazyGetSignUrlToUploadDocQuery,
  usePatchDocumentMutation,
  usePostDocumentMutation,
  usePostUploadFileMutation,
} from "@services/api/documents";
import { usePostStatusApplicationMutation, usePutApplicationsMutation } from "@services/api/applications";
import { useGetFileCategoriesQuery, useLazyGetStatusQuery } from "@services/api/catalogs";
import {
  setApplicationStateCode,
  setCompanyDocsValidated,
  setExistSDC,
  setShowValidateFileModal,
} from "@store/slices/appSlice";

export const CompanyFileTab = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [checkForChange, setCheckForChange] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [childrenModal, setChildrenModal] = useState<"VALIDATE" | "REJECT" | "VALIDATEVISIT">();
  const [currentVerticalTab, setCurrentVerticalTab] = useState<number>(0);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [documentTriggered, setDocumentTriggered] = useState<DocumentInfo>();
  const [documentActionTriggered, setDocumentActionTriggered] = useState<DocumentAction>();
  const [companyTabs, setCompanyTabs] = useState<Array<any>>([]);
  const [reprLegal, setReprLegal] = useState<Person>();

  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  const permissions = (state = store.getState()) => state.app.session.permissions;
  const sessionPermissions = createSelector([permissions], (permissions) => {
    return permissions?.filter((permission) =>
      ["MGEN", "MEXE", "MDIC", "MVOC", "MCON", "MRCR"].includes(permission.module.code),
    );
  });

  const [triggerGetDocuments, resultGetDocuments] = useLazyGetDocumentsByAppQuery({
    selectFromResult: ({ data }) => ({
      documents: data?.data?.company,
    }),
  });

  const documenCategories = useGetFileCategoriesQuery();

  const [triggerGetStatus] = useLazyGetStatusQuery();
  const [triggerPostDocument] = usePostDocumentMutation();
  const [triggerPatchDocument] = usePatchDocumentMutation();
  const [triggerPostUploadDoc] = usePostUploadFileMutation();
  const [triggerPutApplication] = usePutApplicationsMutation();
  const [triggerPatchApplication] = usePutApplicationsMutation();
  const [triggerPostUrlDocument] = useGetUrlToDownloadDocMutation();
  const [triggetPostUrlToUploadDoc] = useLazyGetSignUrlToUploadDocQuery();
  const [triggerPostStatusApplication] = usePostStatusApplicationMutation();

  const handleOnChangeVerticalTab = (_: string, index: number) => {
    setCurrentVerticalTab(index);
  };

  const handleOnModalBackClicked = () => {
    setShowConfirmModal(false);
  };

  const handleOnModalConfirmClicked = async () => {
    setIsProcessing(true);
    if (documentActionTriggered === DocumentAction.REJECT) {
      const statusCatalog = await triggerGetStatus();
      const currentStatusId = JSON.parse(applicationSelected.fullData)["status_id"];
      const currentStatusCode = statusCatalog?.data?.data.data.find((item) => item.id === currentStatusId)?.code;

      setCheckForChange(true);

      if (currentStatusCode === "CSEDC") {
        await triggerPostStatusApplication({
          applicationId: applicationSelected.id ?? "0-0-0-0-0",
          statusCode: "CSECI",
          disableSequenceCheck: true,
        }).unwrap();
      }

      if (documentTriggered?.category_code === "SDC") {
        await triggerPatchApplication({
          applicationId: applicationSelected.id,
          body: {
            completed: false,
            loan_application_completed: false,
            references_completed: false,
            bank_information_completed: false,
          },
        });
      }

      if (["DIORL", "DAC", "DAA"].find((doc) => doc === documentTriggered?.category_code)) {
        await triggerPatchApplication({
          applicationId: applicationSelected.id,
          body: {
            decision_validated: false,
          },
        });
      }

      const loan_application_completed = ["DCD"].find((doc) => doc === documentTriggered?.category_code) ? false : null;
      const bank_information_completed = ["DECB"].find((doc) => doc === documentTriggered?.category_code)
        ? false
        : null;

      if (loan_application_completed === false || bank_information_completed === false) {
        await triggerPatchApplication({
          applicationId: applicationSelected.id,
          body: {
            completed: false,
            loan_application_completed,
            bank_information_completed,
          },
        });
      }

      await triggerGetDocuments({ applicationId: applicationSelected.id, type: "company" });
    } else if (documentActionTriggered === DocumentAction.VALIDATE) {
      setCheckForChange(true);
      try {
        await triggerPatchDocument({
          applicationId: applicationSelected.id,
          documentId: documentTriggered?.id ?? "0-0-0-0-0",
          body: {
            validated: true,
          },
        }).unwrap();

        if (documentTriggered?.category_code === "CDVOC") {
          await triggerPostStatusApplication({
            applicationId: applicationSelected.id ?? "0-0-0-0-0",
            statusCode: "CSEVD",
            disableSequenceCheck: true,
          }).unwrap();

          dispatch(setApplicationStateCode("CSEVD"));
        }

        await triggerGetDocuments({ applicationId: applicationSelected.id, type: "company" });
      } catch (error) {
        console.log("Error al validar documento: %s", error);
      }
    }
    setShowConfirmModal(false);
    setIsProcessing(false);
  };

  const handleOnFileActionClicked = async (
    action: DocumentAction,
    file: DocumentInfo,
    newFile?: EventTarget & HTMLInputElement,
  ) => {
    if (action === DocumentAction.SEE) {
      const urlDownload = await triggerPostUrlDocument(file.url);

      const response = await fetch(urlDownload.data ?? "");
      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob], { type: getMimeTypeFromFilename(file.file_name) }));
      window.open(url);
    } else if (action === DocumentAction.DOWNLOAD) {
      const urlDownload = await triggerPostUrlDocument(file.url);
      const response = await fetch(urlDownload.data ?? "");
      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("target", "_blank");
      link.setAttribute("download", file.file_name);

      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } else if (action === DocumentAction.UPLOAD) {
      const tmpFile = newFile?.files?.[0];
      try {
        if (file.id === undefined) {
          file.client_details_id = JSON.parse(applicationSelected.fullData)["client_details_id"];
        }

        const urlToUploadResponse = await triggetPostUrlToUploadDoc({
          upload_type: "client",
          file_name: tmpFile?.name ?? "",
          regimen: "pm",
          person_id: file.person_id,
          client_details_id: file.client_details_id,
          file_category_id: file.file_category_id,
          loan_application_id: applicationSelected.id,
        }).unwrap();

        const signUrl = urlToUploadResponse.data;

        const tmp = new FormData();

        tmp.append("key", signUrl.fields.key);
        tmp.append("AWSAccessKeyId", signUrl.fields.AWSAccessKeyId);
        tmp.append("x-amz-security-token", signUrl.fields["x-amz-security-token"]);
        tmp.append("policy", signUrl.fields.policy);
        tmp.append("signature", signUrl.fields.signature);
        tmp.append("file", tmpFile);

        await triggerPostUploadDoc({
          url: signUrl.url,
          body: tmp,
        }).unwrap();

        if (file.id !== undefined) {
          await triggerPatchDocument({
            applicationId: applicationSelected.id,
            documentId: file.id,
            body: {
              status: false,
            },
          }).unwrap();
        }

        await triggerPostDocument({
          loan_application_id: applicationSelected.id ?? "0-0-0-0-0",
          file_category_id: file.file_category_id ?? "0-0-0-0-0",
          url: signUrl.fields.key,
          file_name: tmpFile?.name ?? "",
          client_details_id: file.category_code === "VIDME" ? undefined : file.client_details_id,
          person_id: file.category_code === "VIDME" ? file.person_id : undefined,
        }).unwrap();
      } catch (error) {
      } finally {
        tmpFile.value = "";
        await triggerGetDocuments({ applicationId: applicationSelected.id, type: "company" });
      }
    } else if (action === DocumentAction.VALIDATE) {
      setDocumentActionTriggered(action);
      setDocumentTriggered(file);
      setChildrenModal("VALIDATE");
      setShowConfirmModal(true);
    } else if (action === DocumentAction.REJECT) {
      setDocumentActionTriggered(action);
      setDocumentTriggered(file);
      setChildrenModal("REJECT");
      setShowConfirmModal(true);
    }
  };

  const handleOnToGoClicked = async () => {
    navigate("/dictamen");
  };

  const handleValidateClicked = () => {
    dispatch(setShowValidateFileModal(true));
  };

  const disableGoToDictamen = useMemo(() => {
    if (!resultGetDocuments) return true;

    const result = ["DIORL", "DAC"]
      .map((code) => resultGetDocuments.documents?.grals.some((item) => item.category_code === code))
      .every((flag) => flag === true);

    return !result;
  }, [resultGetDocuments]);

  useEffect(() => {
    const docsToValidated = [];
    const checkValidateFolder = [];
    if (applicationSelected.tax_systems.code === "CS002") {
      //PFAE
      docsToValidated.push("DCD", "SDC"); //Se remueve la constancia de situación fiscal -> DCDF
      checkValidateFolder.push("DCD", "SDC"); //Se remueve la constancia de situación fiscal -> DCDF
    } else {
      docsToValidated.push("DCD", "SDC", "DIORL", "DAC", "CDDTN"); //Se remueve la constancia de situación fiscal -> DCDF
      checkValidateFolder.push("DCD", "SDC"); //Se remueve la constancia de situación fiscal -> DCDF
    }

    const isCompletedFolder =
      checkValidateFolder
        .map((code) => resultGetDocuments.documents?.grals.some((doc) => doc.category_code == code && doc.validated))
        .every((flag) => flag === true) ?? false;

    const result =
      docsToValidated
        .map((code) => resultGetDocuments.documents?.grals.some((doc) => doc.category_code == code && doc.validated))
        .every((flag) => flag === true) ?? false;

    const docs_accounts_validated =
      resultGetDocuments.documents?.accounts
        .map((account) => account.bank_statements.every((statement) => statement.validated))
        .every((t) => t === true) ?? false;

    const docs_accounts_completed =
      resultGetDocuments.documents?.accounts
        .map((account) => account.bank_statements.length === 3)
        .every((t) => t === true) ?? false;

    if (checkForChange && isCompletedFolder && docs_accounts_completed && docs_accounts_validated) {
      try {
        triggerPutApplication({
          applicationId: applicationSelected.id,
          body: {
            completed: true,
          },
        }).unwrap();
      } catch (error) {
        console.log(error);
        console.error("No se pudo cambiar el estado de la empresa a completed");
      } finally {
        setCheckForChange(false);
      }
    }

    const existsSDC = resultGetDocuments?.documents?.grals.some((item) => item.category_code === "SDC") ?? false;
    dispatch(setExistSDC(existsSDC));

    dispatch(setCompanyDocsValidated(result && docs_accounts_validated && docs_accounts_completed));
  }, [resultGetDocuments]);

  useEffect(() => {
    const tabs = ["MGEN", "MEXE", "MDIC", "MVOC", "MCON", "MRCR"];

    setCurrentVerticalTab(
      tabs.findIndex(
        (item) => item === sessionPermissions(store.getState()).find((permission) => permission.can_watch)?.module.code,
      ),
    );

    triggerGetDocuments({ applicationId: applicationSelected.id, type: "company" });

    triggerGetStatus().then((values) => {
      const currentStatusId = JSON.parse(applicationSelected.fullData)["status_id"];
      const currentStatusCode = values.data?.data?.data?.find((item) => item.id === currentStatusId)?.code;

      const bFlag = ["CSEDV", "CSAVC", "CSVYC", "CSIYA", "CSREC", "CSRDO", "CSFIR", "CSEVD"].find(
        (item) => item === currentStatusCode,
      );

      setCompanyTabs([
        {
          title: "Perfil",
          active: true,
          hidden:
            sessionPermissions(store.getState()).find(
              (permission) => permission.module.code === "MGEN" && permission.can_watch,
            ) === undefined,
        },
        {
          title: "Expediente",
          active: true,
          hidden:
            sessionPermissions(store.getState()).find(
              (permission) => permission.module.code === "MEXE" && permission.can_watch,
            ) === undefined,
        },
        {
          title: "Dictamen",
          active: true,
          hidden:
            sessionPermissions(store.getState()).find(
              (permission) => permission.module.code === "MDIC" && permission.can_watch,
            ) === undefined || applicationSelected.tax_systems.code === "CS002",
        },
        {
          title: "Visita Ocular",
          active: bFlag,
          hidden:
            sessionPermissions(store.getState()).find(
              (permission) => permission.module.code === "MVOC" && permission.can_watch,
            ) === undefined,
        },
        {
          title: "Contractuales",
          active: bFlag,
          hidden:
            sessionPermissions(store.getState()).find(
              (permission) => permission.module.code === "MCON" && permission.can_watch,
            ) === undefined,
        },
        {
          title: "Resumen crédito",
          active: bFlag,
          hidden:
            sessionPermissions(store.getState()).find(
              (permission) => permission.module.code === "MRCR" && permission.can_watch,
            ) === undefined,
        },
      ]);

      const repLegal = JSON.parse(applicationSelected.fullData)["person"].find(
        (person: Person) => person.person_type?.code === "PTRL",
      );
      setReprLegal(repLegal);
    });
  }, []);

  return (
    <>
      <CustomVerticalTabPanel
        titleTabs={companyTabs}
        alias="vertical"
        sx={{ borderRight: 1, borderColor: "#A3D4E8" }}
        onChangeTab={handleOnChangeVerticalTab}
      />
      <CustomTabPanel alias="vertical" width={"100%"} index={0} value={currentVerticalTab}>
        <Box margin={3} display={0 == currentVerticalTab ? "block" : "none"}>
          <ProfileTab />
        </Box>
      </CustomTabPanel>
      <CustomTabPanel alias="vertical" width={"100%"} index={1} value={currentVerticalTab}>
        <Box margin={3} display={1 == currentVerticalTab ? "block" : "none"}>
          <Typography variant="h4" fontWeight={600} color={"#002652"}>
            Expediente
          </Typography>
          <FileSectionV2
            showHeaders
            showValidate
            showReject
            title="Comprobante de domicilio"
            documents={
              resultGetDocuments?.documents?.grals.some((item) => item.category_code === "DCD")
                ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "DCD")
                : generateDummyDocuments(
                    1,
                    documenCategories.currentData?.find((category) => category.code === "DCD"),
                  )
            }
            onFileActionClick={handleOnFileActionClicked}
          />
          <FileSectionV2
            showHeaders
            showValidate
            showReject
            title="Comprobante de domicilio confirmado"
            documents={
              resultGetDocuments?.documents?.grals.some((item) => item.category_code === "DCDC")
                ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "DCDC")
                : generateDummyDocuments(
                    1,
                    documenCategories.currentData?.find((category) => category.code === "DCDC"),
                  )
            }
            onFileActionClick={handleOnFileActionClicked}
          />
          <FileSectionV2
            showValidate
            showReject
            title="Solicitud de crédito"
            documents={
              resultGetDocuments?.documents?.grals.some((item) => item.category_code === "SDC")
                ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "SDC")
                : generateDummyDocuments(
                    1,
                    documenCategories.currentData?.find((category) => category.code === "SDC"),
                  )
            }
            onFileActionClick={handleOnFileActionClicked}
          />
          <FileSectionV2
            showValidate
            showReject
            title="Constancia de situación fiscal"
            documents={
              resultGetDocuments?.documents?.grals.some((item) => item.category_code === "DCDF")
                ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "DCDF")
                : generateDummyDocuments(
                    1,
                    documenCategories.currentData?.find((category) => category.code === "DCDF"),
                  )
            }
            onFileActionClick={handleOnFileActionClicked}
          />
          {resultGetDocuments?.documents?.accounts.map((account, index) => (
            <FileSectionV2
              key={index}
              showValidate
              showReject
              title="Información bancaria"
              extras={[
                { title: "Cuenta CLABE", value: account.clabe },
                { title: "Banco", value: account.bank_name },
              ]}
              documents={account.bank_statements}
              onFileActionClick={handleOnFileActionClicked}
            />
          ))}
          {resultGetDocuments?.documents?.accounts.length === 0 && (
            <Alert sx={{ my: 2 }} severity="error">
              Falta información bancaria de la compañia
            </Alert>
          )}
          <FileSectionV2
            showValidate
            showReject
            title="Verificación de identidad - Representante Legal"
            documents={
              resultGetDocuments?.documents?.grals.some((item) => item.category_code === "VIDME")
                ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "VIDME")
                : generateDummyDocuments(
                    1,
                    documenCategories.currentData?.find((category) => category.code === "VIDME"),
                    reprLegal?.id,
                  )
            }
            onFileActionClick={handleOnFileActionClicked}
          />
          <FileSectionV2
            showValidate
            showReject
            title="Visita ocular"
            documents={
              resultGetDocuments?.documents?.grals.some((item) => item.category_code === "CDVOC")
                ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "CDVOC")
                : generateDummyDocuments(
                    1,
                    documenCategories.currentData?.find((category) => category.code === "CDVOC"),
                  )
            }
            onFileActionClick={handleOnFileActionClicked}
          />
        </Box>
        <Box mt={10} display={"flex"} justifyContent={"flex-end"} columnGap={2}>
          <Button
            variant="zomp"
            disabled={
              ["CSEDV", "CSAVC", "CSEVD", "CSVYC", "CSFIR", "CSVCF", "CSIYA", "CSREC", "CSRDO"].some(
                (item) => item === applicationSelected.status_code,
              ) ||
              (applicationSelected.tax_systems.code === "CS001" &&
                (applicationSelected.company_docs_validated == false ||
                  applicationSelected.shareholder_docs_validated == false ||
                  applicationSelected.owners_docs_validated == false)) ||
              (applicationSelected.tax_systems.code === "CS002" && applicationSelected.company_docs_validated == false)
            }
            onClick={handleValidateClicked}
          >
            Validar expediente
          </Button>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel alias="vertical" width={"100%"} index={2} value={currentVerticalTab}>
        <Box margin={3} display={2 == currentVerticalTab ? "block" : "none"}>
          <Typography variant="h4" fontWeight={600} color={"#002652"}>
            Dictamen
          </Typography>
          <FileSectionV2
            showValidate
            showReject
            title="Identificación representante legal"
            documents={
              resultGetDocuments?.documents?.grals.some((item) => item.category_code === "DIORL")
                ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "DIORL")
                : generateDummyDocuments(
                    1,
                    documenCategories.currentData?.find((category) => category.code === "DIORL"),
                  )
            }
            showHeaders
            onFileActionClick={handleOnFileActionClicked}
          />
          <FileSectionV2
            showValidate
            showReject
            title="Acta constitutiva"
            documents={
              resultGetDocuments?.documents?.grals.some((item) => item.category_code === "DAC")
                ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "DAC")
                : generateDummyDocuments(
                    1,
                    documenCategories.currentData?.find((category) => category.code === "DAC"),
                  )
            }
            onFileActionClick={handleOnFileActionClicked}
          />
          <FileSectionV2
            showValidate
            showReject
            title="Actas adicionales"
            documents={
              resultGetDocuments?.documents?.grals.some((item) => item.category_code === "DAA")
                ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "DAA")
                : generateDummyDocuments(
                    1,
                    documenCategories.currentData?.find((category) => category.code === "DAA"),
                  )
            }
            onFileActionClick={handleOnFileActionClicked}
          />
          <Typography variant="h4" fontWeight={600} color={"#002652"} pt={4}>
            Módulo Dictamen
          </Typography>
          <FileSectionV2
            showValidate
            showReject
            title=""
            documents={
              resultGetDocuments?.documents?.grals.some((item) => item.category_code === "CDDTN")
                ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "CDDTN")
                : generateDummyDocuments(
                    1,
                    documenCategories.currentData?.find((category) => category.code === "CDDTN"),
                  )
            }
            onFileActionClick={handleOnFileActionClicked}
            onToGoClick={handleOnToGoClicked}
            showButtonGo
            showHeaders
          />
        </Box>
      </CustomTabPanel>
      <CustomTabPanel alias="vertical" width={"100%"} index={3} value={currentVerticalTab}>
        <OnSiteVisit
          applicationId={applicationSelected.id}
          clientDetailsId={JSON.parse(applicationSelected.fullData)["client_details_id"]}
        />
      </CustomTabPanel>
      <CustomTabPanel alias="vertical" width={"100%"} index={4} value={currentVerticalTab}>
        <Box display={4 == currentVerticalTab ? "block" : "none"}>
          <ContractDocsTab />
        </Box>
      </CustomTabPanel>
      <CustomTabPanel alias="vertical" width={"100%"} index={5} value={currentVerticalTab}>
        <Box margin={3}>
          <ResumeTab />
        </Box>
      </CustomTabPanel>
      <CustomModal open={showConfirmModal}>
        {/* {childrenModal === "VALIDATEVISIT" && (
          <ValidateDialogSegment
            type={"DOCVALIDATE"}
            title={`Estas seguro validar la visita ocular de:`}
            isProcessing={isProcessing}
            onBackClick={handleOnModalBackClicked}
            onContinueClick={handleOnModalConfirmValidateVisitClicked}
          />
        )} */}
        {childrenModal === "VALIDATE" && (
          <ValidateDialogSegment
            type={"DOCVALIDATE"}
            title={`Estas seguro de validar el documento ${documentTriggered?.category_name} de:`}
            isProcessing={isProcessing}
            onBackClick={handleOnModalBackClicked}
            onContinueClick={handleOnModalConfirmClicked}
          />
        )}
        {childrenModal === "REJECT" && (
          <RejectDocumentDialogSegment
            document={documentTriggered}
            isProcessing={isProcessing}
            onBackClick={handleOnModalBackClicked}
            onConfirmClick={handleOnModalConfirmClicked}
          />
        )}
      </CustomModal>
    </>
  );
};
